<template>
  <div class="warehousestatelist">
    <v-progress-circular v-if="this.saving"
        indeterminate
        color="green"
      ></v-progress-circular>
      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="3">
             Knjiga IRA
            </v-col>
            <v-col cols="4">
              <v-btn @click="print" class="mr-2">Printaj</v-btn>
            </v-col>
            <v-col cols="5">
              <v-text-field
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                v-model="search"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
            dense
            :headers="headers"
            :items="items"
            item-key="id"
            :search="search"
            :footer-props="{
              'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            :items-per-page="50"
            @click:row="navigateTo"
          >
          <template v-slot:[`item.BookID`]="{ item }">
              <span>{{ parseInt(item.BookID) }}</span>
          </template>
          <template v-slot:[`item.InvoiceDate`]="{ item }">
              <span>{{ localDate(item.InvoiceDate) }}</span>
          </template>
         
          <template v-slot:[`item.InvoiceAmount`]="{ item }">
              <span>{{ formatPrice(item.InvoiceAmount) }}</span>
          </template>

          <template v-slot:[`item.VAT4Per`]="{ item }">
              <span>{{ formatPrice(item.VAT4Per) }}</span>
          </template>
          <template v-slot:[`item.VAT4Base`]="{ item }">
              <span>{{ formatPrice(item.VAT4Base) }}</span>
          </template>
          <template v-slot:[`item.VAT4Amount`]="{ item }">
              <span>{{ formatPrice(item.VAT4Amount) }}</span>
          </template>
          <template v-slot:[`item.VAT4PerCanUse`]="{ item }">
              <span>{{ formatPrice(item.VAT4PerCanUse) }}</span>
          </template>
          <template v-slot:[`item.VAT4CanUse`]="{ item }">
              <span>{{ formatPrice(item.VAT4CanUse) }}</span>
          </template>
          <template v-slot:[`item.VAT4CanTUse`]="{ item }">
              <span>{{ formatPrice(item.VAT4CanTUse) }}</span>
          </template>
          <template v-slot:[`item.VAT5Per`]="{ item }">
              <span>{{ formatPrice(item.VAT5Per) }}</span>
          </template>
          <template v-slot:[`item.VAT5Base`]="{ item }">
              <span>{{ formatPrice(item.VAT5Base) }}</span>
          </template>
          <template v-slot:[`item.VAT5Amount`]="{ item }">
              <span>{{ formatPrice(item.VAT5Amount) }}</span>
          </template>
          <template v-slot:[`item.VAT5PerCanUse`]="{ item }">
              <span>{{ formatPrice(item.VAT5PerCanUse) }}</span>
          </template>

          <template v-slot:[`item.VAT3Base`]="{ item }">
              <span>{{ formatPrice(item.VAT3Base) }}</span>
          </template>
          <template v-slot:[`item.VAT3Amount`]="{ item }">
              <span>{{ formatPrice(item.VAT3Amount) }}</span>
          </template>
          <template v-slot:[`item.VAT2Base`]="{ item }">
              <span>{{ formatPrice(item.VAT2Base) }}</span>
          </template>
          <template v-slot:[`item.VAT2Amount`]="{ item }">
              <span>{{ formatPrice(item.VAT2Amount) }}</span>
          </template>
          <template v-slot:[`item.VAT1Base`]="{ item }">
              <span>{{ formatPrice(item.VAT1Base) }}</span>
          </template>                    
          <template v-slot:[`item.VAT1Amount`]="{ item }">
              <span>{{ formatPrice(item.VAT1Amount) }}</span>
          </template>

          <template slot="pageText" slot-scope="{ pageStart, pageStop }">
            From {{ pageStart }} to {{ pageStop }}
          </template>          
          <template slot="body.append">
                <tr>
                    <th></th>
                    <th></th> 
                    <th></th>
                    <th></th>
                    <th>Ukupno:</th>                    
                    <th class="text-right">{{ formatPrice(TotalInvoiceAmount) }}</th>
                    <th class="text-right">{{ formatPrice(TotalVAT4Per)}}</th>
                    <th class="text-right">{{ formatPrice(TotalVAT4Base)}}</th>
                    <th class="text-right">{{ formatPrice(TotalVAT4Amount)}}</th>
                    <th class="text-right">{{ formatPrice(TotalVAT4PerCanUse)}}</th>
                    <th class="text-right">{{ formatPrice(TotalVAT4CanUse)}}</th>
                    <th class="text-right">{{ formatPrice(TotalVAT4CanTUse)}}</th>
                    <th class="text-right">{{ formatPrice(TotalVAT5Per)}}</th>
                    <th class="text-right">{{ formatPrice(TotalVAT5Base)}}</th>
                    <th class="text-right">{{ formatPrice(TotalVAT5Amount)}}</th>
                    <th class="text-right">{{ formatPrice(TotalVAT5PerCanUse)}}</th>
                    <th class="text-right">{{ formatPrice(TotalVAT3Base)}}</th>
                    <th class="text-right">{{ formatPrice(TotalVAT3Amount)}}</th>
                    <th class="text-right">{{ formatPrice(TotalVAT2Base)}}</th>
                    <th class="text-right">{{ formatPrice(TotalVAT2Amount)}}</th> 
                    <th class="text-right">{{ formatPrice(TotalVAT1Base) }}</th>
                    <th class="text-right">{{ formatPrice(TotalVAT1Amount) }}</th>
                </tr>
          </template>
        </v-data-table>
      </v-card>
      <appMainLedgerPrintOut ref="prnt" :ira="ira"></appMainLedgerPrintOut>
      <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      bottom
      :color="color"
      >
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
// import axios from 'axios'
import langEn from './MainLedgerDescEN'
import langHr from './MainLedgerDescHR'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import MainLedgerService from '@/services/MainLedgerService'
import MainBookService from '@/services/MainBookService'
import dateFormat from '@/snippets/dateFormat'
import MainLedgerPrintOut from './MainLedgerPrintOut'
import {parseNum, round, sortArrayOfObjects} from '@/snippets/allSnippets'

export default {
  name: 'mainledgerbookout',
  props: ['mainledgerAna', 'source'],
  data () {    
    return {
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      items: [],
      documentName: '',
      documentNameLoc: '',
      msg: '',
      tmp: '',
      search: '',
      pagination: {},
      rowsPerPageItems: [50],
      headers: [ ],
      mainQuery: {
        CompanyId: 1,
        businessYear: 0,
        documentName: '',
        databaseDocID: 0
      },
      columns: [],
      rows: [],
      lang: {},
      langC: {},
      saving: false,
      documentAna: {},
      ira: {},
      TotalVAT3Base : 0,
      TotalVAT2Base : 0,
      TotalVAT1Base : 0,
      TotalInvoiceAmount : 0,
      TotalAmountVAT : 0,
      TotalVAT3Amount : 0,
      TotalVAT2Amount : 0,
      TotalVAT1Amount : 0,
      TotalVAT4Per: 0,
      TotalVAT4Base: 0,
      TotalVAT4Amount: 0,
      TotalVAT4PerCanUse: 0,
      TotalVAT4CanUse: 0,
      TotalVAT4CanTUse: 0,
      TotalVAT5Per: 0,
      TotalVAT5Base: 0,
      TotalVAT5Amount: 0,
      TotalVAT5PerCanUse: 0,
      TotalVAT5CanUse: 0,
    }
  },
  
  async mounted () {
    try {
        // do analysis from database
        if (this.$store.state.language === 'EN') {
          this.lang = langEn
          this.langC = commEn
        }
        if (this.$store.state.language === 'HR') {
          this.lang = langHr
          this.langC = commHr
        }
        //this.items = this.$store.state.warehouseState ? this.$store.state.warehouseState : []
        this.headers.push(
          {text: this.lang.BookID, value: 'BookID', width: '70px', align: 'start'}, 
          {text: this.lang.InvoiceNumber, value: 'InvoiceNumber', width: '120px', align: 'start'},
          {text: this.lang.InvoiceDate, value: 'InvoiceDate', width: '120px', align: 'start'},
          {text: this.lang.partnerName, value: 'partnerName', width: '150px', align: 'start'},
          {text: this.lang.HomeCurrency, value: 'HomeCurrency', width: '50px', align: 'center'},

          {text: this.lang.InvoiceAmount, value: 'InvoiceAmount', width: '100px', align: 'end'},
          {text: this.lang.VAT4Per, value: 'VAT4Per', width: '100px', align: 'end'},
          {text: this.lang.VAT4Base, value: 'VAT4Base', width: '100px', align: 'end'},
          {text: this.lang.VAT4Amount, value: 'VAT4Amount', width: '100px', align: 'end'},
          {text: this.lang.VAT4PerCanUse, value: 'VAT4PerCanUse', width: '100px', align: 'end'},
          {text: this.lang.VAT4CanUse, value: 'VAT4CanUse', width: '100px', align: 'end'},
          {text: this.lang.VAT4CanTUse, value: 'VAT4CanTUse', width: '100px', align: 'end'},
          {text: this.lang.VAT5Per, value: 'VAT5Per', width: '100px', align: 'end'},
          {text: this.lang.VAT5Base, value: 'VAT5Base', width: '100px', align: 'end'},
          {text: this.lang.VAT5Amount, value: 'VAT5Amount', width: '100px', align: 'end'},
          {text: this.lang.VAT5PerCanUse, value: 'VAT5PerCanUse', width: '100px', align: 'end'},
          {text: this.lang.VAT3Base, value: 'VAT3Base', width: '100px', align: 'end'},
          {text: this.lang.VAT3Amount, value: 'VAT3Amount', width: '100px', align: 'end'},
          {text: this.lang.VAT2Base, value: 'VAT2Base', width: '100px', align: 'end'},
          {text: this.lang.VAT2Amount, value: 'VAT2Amount', width: '100px', align: 'end'},
          {text: this.lang.VAT1Base, value: 'VAT1Base', width: '100px', align: 'end'},
          {text: this.lang.VAT1Amount, value: 'VAT1Amount', width: '100px', align: 'end'},                
          {text: this.lang.id, value: 'id', width: '50px'}
        )
        // do analysis from database
        this.localError = false
        this.error = null

        this.items = []
        this.saving = true
        let tempItems = null
        if (this.source === 1) {
          const newAna = {...this.mainledgerAna}
          tempItems = (await MainLedgerService.books(this.mainledgerAna)).data.documents
          console.log('1', tempItems, this.mainledgerAna)
          newAna.bookTypeSelected = 104
          const tempItems2 = (await MainLedgerService.books(newAna)).data.documents
          console.log('2',  tempItems2, newAna)
          tempItems2.map((item) => tempItems.push(item))
          
        }
        if (this.source === 2) {
          const locItems = (await MainBookService.choose(this.mainledgerAna)).data.mainbooks
          tempItems = locItems.filter(item => item.BookRecExp === 1) // rec = knjiga ira
        }
        this.saving = false
        let iid = 0
        this.TotalVAT3Base = 0
        this.TotalVAT2Base = 0
        this.TotalVAT1Base = 0
        this.TotalInvoiceAmount = 0
        this.TotalAmountVAT = 0
        this.TotalVAT3Amount = 0
        this.TotalVAT2Amount = 0
        this.TotalVAT1Amount = 0
        this.TotalVAT4Per = 0
        this.TotalVAT4Base = 0
        this.TotalVAT4Amount = 0
        this.TotalVAT4PerCanUse = 0
        this.TotalVAT4CanUse = 0
        this.TotalVAT4CanTUse = 0
        this.TotalVAT5Per = 0
        this.TotalVAT5Base = 0
        this.TotalVAT5Amount = 0
        this.TotalVAT5PerCanUse = 0
        this.TotalVAT5CanUse = 0

        if (tempItems) {
          tempItems.map(item => {

          if (item.InvoiceAmount && round((parseNum(item.InvoiceAmount)),2) !== 0) {   
            const newEntry = {}
            newEntry.BookID = item.BookID ? parseInt(item.BookID) : ''
            newEntry.partnerName = item.partnerName ? item.partnerName : ''
            newEntry.partnerVATID = item.partnerVATID ? item.partnerVATID : ''
            newEntry.InvoiceNumber = (item.InvoiceNumber ? item.InvoiceNumber : '')
            newEntry.InvoiceDate = (item.InvoiceDate ? item.InvoiceDate : null)
            newEntry.HomeCurrency = (item.HomeCurrency ? item.HomeCurrency : '')
            newEntry.VAT3Base = round(parseNum(item.VAT3Base), 2)
            newEntry.VAT2Base = round(parseNum(item.VAT2Base), 2)
            newEntry.VAT1Base = round(parseNum(item.VAT1Base), 2)
            newEntry.InvoiceAmount = round(parseNum(item.InvoiceAmount), 2)
            newEntry.AmountVAT = round(parseNum(item.AmountVAT), 2)
            newEntry.VAT3Amount = round(parseNum(item.VAT3Amount), 2)
            newEntry.VAT2Amount = round(parseNum(item.VAT2Amount), 2)
            newEntry.VAT1Amount = round(parseNum(item.VAT1Amount), 2)

            // book out invoices
            newEntry.VAT4Per = round(parseNum(item.VAT4Per), 2)
            newEntry.VAT4Base = round(parseNum(item.VAT4Base), 2)
            newEntry.VAT4Amount = round(parseNum(item.VAT4Amount), 2)
            newEntry.VAT4PerCanUse = round(parseNum(item.VAT4PerCanUse), 2)
            newEntry.VAT4CanUse = round(parseNum(item.VAT4CanUse), 2)
            newEntry.VAT4CanTUse = round(parseNum(item.VAT4CanTUse), 2)
            newEntry.VAT5Per = round(parseNum(item.VAT5Per), 2)
            newEntry.VAT5Base = round(parseNum(item.VAT5Base), 2)
            newEntry.VAT5Amount = round(parseNum(item.VAT5Amount), 2)
            newEntry.VAT5PerCanUse = round(parseNum(item.VAT5PerCanUse), 2)
            // this.Total1 += parseNum(item.TotalPlus ? item.TotalPlus : 0)
            // this.Total2 += parseNum(item.TotalMinus ? item.TotalMinus : 0)
            this.TotalVAT3Base += round(parseNum(item.VAT3Base), 2)
            this.TotalVAT3Amount += round(parseNum(item.VAT3Amount), 2)
            this.TotalVAT2Base += round(parseNum(item.VAT2Base), 2)
            this.TotalVAT2Amount += round(parseNum(item.VAT2Amount), 2)
            this.TotalVAT1Base += round(parseNum(item.VAT1Base), 2)            
            this.TotalVAT1Amount += round(parseNum(item.VAT1Amount), 2)
            this.TotalInvoiceAmount += round(parseNum(item.InvoiceAmount), 2)
            this.TotalAmountVAT += round(parseNum(item.AmountVAT), 2)
            
            // boot out invoices
            this.TotalVAT4Per += round(parseNum(item.VAT4Per), 2)
            this.TotalVAT4Base += round(parseNum(item.VAT4Base), 2)
            this.TotalVAT4Amount += round(parseNum(item.VAT4Amount), 2)
            this.TotalVAT4PerCanUse += round(parseNum(item.VAT4PerCanUse), 2)
            this.TotalVAT4CanUse += round(parseNum(item.VAT4CanUse), 2)
            this.TotalVAT4CanTUse += round(parseNum(item.VAT4CanTUse), 2)
            this.TotalVAT5Per += round(parseNum(item.VAT5Per), 2)
            this.TotalVAT5Base += round(parseNum(item.VAT5Base), 2)
            this.TotalVAT5Amount += round(parseNum(item.VAT5Amount), 2)
            this.TotalVAT5PerCanUse += round(parseNum(item.VAT5PerCanUse), 2)
            iid += 1
            newEntry.id = iid
            this.items.push(newEntry)
          }
          })
        }
        this.items = sortArrayOfObjects(this.items, "BookID", "asc")
        this.typeAna = 4
      } catch (error) {
        this.error = 'Greska u konekciji na bazu' + error.detail
      }      
  },
  methods: {     
    navigateTo () {
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    localDate(dateToFormat) {
      return dateFormat(dateToFormat)
    },
    print(){
      this.initIra()
      this.$refs.prnt.print()
    },
    initIra() {
      this.ira = {}
      this.ira.OIB = this.$store.state.companyActiv.CompanyVATID ? this.$store.state.companyActiv.CompanyVATID : ''
      this.ira.Naziv = this.$store.state.companyActiv.CompanyName ? this.$store.state.companyActiv.CompanyName : ''
      this.ira.Mjesto = (this.$store.state.companyActiv.CompanyPostcode ? this.$store.state.companyActiv.CompanyPostcode : '') + ' ' +this.$store.state.companyActiv.CompanyCity ? this.$store.state.companyActiv.CompanyCity : ''
      this.ira.Ulica = this.$store.state.companyActiv.CompanyStreet ? this.$store.state.companyActiv.CompanyStreet : ''
      this.ira.Broj = this.$store.state.companyActiv.CompanyBuildingNameNumber ? this.$store.state.companyActiv.CompanyBuildingNameNumber : ''
      this.ira.DodatakKucnomBroju = ''
      this.ira.DatumOd = this.mainledgerAna.docFromDate
      this.ira.DatumDo = this.mainledgerAna.docToDate 
      this.ira.PodrucjeDjelatnosti = this.$store.state.companyActiv.CompanyInvoiceType ? this.$store.state.companyActiv.CompanyInvoiceType : ''
      this.ira.SifraDjelatnosti = this.$store.state.companyActiv.CompanyInvoiceHint ? this.$store.state.companyActiv.CompanyInvoiceHint : ''
      this.ira.Ime = this.$store.state.user.First ? this.$store.state.user.First : ''
      this.ira.Prezime = this.$store.state.user.Last ? this.$store.state.user.Last : ''
      this.ira.Ukupno = {}
      this.ira.TotalVAT3Base = this.TotalVAT3Base ? this.TotalVAT3Base :  0
      this.ira.TotalVAT2Base = this.TotalVAT2Base ? this.TotalVAT2Base :  0
      this.ira.TotalVAT1Base = this.TotalVAT1Base ? this.TotalVAT1Base : 0
      this.ira.TotalInvoiceAmount = this.TotalInvoiceAmount ? this.TotalInvoiceAmount : 0
      this.ira.TotalAmountVAT = this.TotalAmountVAT ? this.TotalAmountVAT : 0
      this.ira.TotalVAT3Amount = this.TotalVAT3Amount ? this.TotalVAT3Amount : 0
      this.ira.TotalVAT2Amount = this.TotalVAT2Amount ? this.TotalVAT2Amount : 0
      this.ira.TotalVAT1Amount = this.TotalVAT1Amount ? this.TotalVAT1Amount : 0
      this.ira.TotalVAT4Per = this.TotalVAT4Per ? this.TotalVAT4Per : 0
      this.ira.TotalVAT4Base = this.TotalVAT4Base ? this.TotalVAT4Base : 0
      this.ira.TotalVAT4Amount = this.TotalVAT4Amount ? this.TotalVAT4Amount : 0
      this.ira.TotalVAT4PerCanUse = this.TotalVAT4PerCanUse ? this.TotalVAT4PerCanUse : 0
      this.ira.TotalVAT4CanUse = this.TotalVAT4CanUse ? this.TotalVAT4CanUse : 0
      this.ira.TotalVAT4CanTUse = this.TotalVAT4CanTUse ? this.TotalVAT4CanTUse : 0
      this.ira.TotalVAT5Per = this.TotalVAT5Per ? this.TotalVAT5Per : 0
      this.ira.TotalVAT5Base = this.TotalVAT5Base ? this.TotalVAT5Base : 0
      this.ira.TotalVAT5Amount = this.TotalVAT5Amount ? this.TotalVAT5Amount : 0
      this.ira.TotalVAT5PerCanUse = this.TotalVAT5PerCanUse ? this.TotalVAT5PerCanUse : 0
      this.ira.TotalVAT5CanUse = this.TotalVAT5CanUse ? this.TotalVAT5CanUse : 0
      this.ira.Racuni = this.items ? this.items.slice() : []
    },
  },
  computed: {

  },
  watch: {

  },
  components: {
    appMainLedgerPrintOut: MainLedgerPrintOut
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>


