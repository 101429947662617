<template>
  <div>

  </div>
</template>

<script>
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import langEn from './salarieDescEn'
import langHr from './salarieDescHR'
import langDE from './salarieDE'
import commEn from '@/language/commonEN'
import commHr from '@/language/commonHR'
import EmployeeService from '@/services/EmployeeService'
import dayjs from 'dayjs'
// import { parseNum } from '@/snippets/allSnippets'

export default {
  name: 'salarieprinttotal',
  props: [],
  data () {
    return {
      lang: {},
      langC: {},
      msg: '',
      documentName: '',
      documentName2: '',
      document: {},
      havePath: '',
      locCreatedAt: '',
      locUpdatedAt: '',
      locDocument: {},
      striped: false,
      bordered: false,
      outlined: false,
      small: false,
      hover: false,
      dark: false,
      fixed: false,
      footClone: false,
      comp: {},
      mainQuery: {},
      ana: {},
      empl:[],
      printDE: false,
      do2024: false
    }
  },
  mounted () {


  },
  methods: {
    async print (de) {
      if (this.$store.state.companyid) {
        this.mainQuery.CompanyId = this.$store.state.companyid
      }
      if (this.$store.state.businessYear) {
        this.mainQuery.BusinessYear = this.$store.state.businessYear
      }
      if (this.$store.state.language === 'EN') {
        this.lang = {...langEn}
        this.langC = {...commEn}
      }
      if (this.$store.state.language === 'HR') {
        this.lang = {...langHr}
        this.langC = {...commHr}
      }
      if (de) {
        this.printDE = true
        this.lang = {...langDE}
        this.langC = {...commHr}
      }
      if (this.$store.state.documentActivHead.Month < 12 && this.$store.state.documentActivHead.Year < 2023) {
      this.do2024 = false
      }
      if (this.$store.state.documentActivHead.Month === 12 && this.$store.state.documentActivHead.Year === 2023) {
        this.do2024 = true
      }
      if (this.$store.state.documentActivHead.Year === 2024) {
        this.do2024 = true
      }
      await this.fillEmp()
      this.printDoc()
    },
    async fillEmp() {
        this.empl = []
        await EmployeeService.choose3(this.mainQuery)
          .then(res => {
            if (res.data && res.data.employees) {
              this.empl = res.data.employees
              //console.log('all emp', this.empl)
            }
          })
          .catch(err => {
            console.log('Error ', err)
          })
    },
    formatPrice (value) {
      if (this.$store.state.language === 'EN') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      if (this.$store.state.language === 'HR') {
        let val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    },
    formatPriceHR (value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
     addLineDash () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1,
                dash: { length: 1 },
              }
            ]
          }
      this.content.push(docPart)
    },
    printDoc () {
      this.comp = this.$store.state.companyActiv
      this.ana = this.$store.state.printObj ? this.$store.state.printObj : {}
      //console.log('this.ana ', this.ana)

      this.initDocument()
      // map array 
      // new page, not first time 
      if (this.ana.head1) {
        const howMany = this.ana.head1.length
        let printed = 0
        this.ana.head1.map(item => {
          printed +=1
          this.addHead(item)
          this.addLine()
          this.addHeadMain(item)
          this.addLine()
          this.addEmptyRow()
          this.addHoursHeader()
          this.addLineDash()
          this.addHours(item)
          this.addLineDash()
          //this.addMainHeader()
          this.addMain1(item)
          this.addLineDash(item)
          if (!this.do2024) {
            this.addMain2(item)
          }
          if (this.do2024) {
            this.addMain2_2024(item)
          }
          this.addLineDash(item)
          this.addMain3(item)
          this.addLineDash(item)
          this.addMain4(item)
          this.addLineDash(item)
          if (printed < howMany) {
            this.skipNewPage()
          }
          
        })
      }
  
      this.docDefinition.content = this.content
      pdfMake.createPdf(this.docDefinition).open()
    },
    initDocument () {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      this.docDefinition = {}
      this.docDefinition.pageSize = 'A4'
      this.docDefinition.pageOrientation = 'portrait'
      this.docDefinition.pageMargins = [ 20, 20, 20, 40 ]
      this.content = []
    },
    skipNewPage() {
      const docPart01 = {
      columns: [
          {
            width: '40%',
            fontSize: 7,
            bold: true,
            alignment: 'left',
            text: '  ',
            pageBreak: 'after'
          },
        ]
      }
      this.content.push(docPart01)
    },
  
    addEmptyRow () {
      const docPart = {
            columns: [
              {
                width: '20%',
                text: [
                  { text: ' \n ', fontSize: 8 }
                ]
              },
              {
                width: '60%',
                text: [
                  { text: ' ', fontSize: 8 }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },
    addLine () {
      const docPart = {
            canvas: [
              {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 550,
                y2: 0,
                lineWidth: 1
              }
            ]
          }
      this.content.push(docPart)
    },

    addHead(item) {
      const empl = this.empl.find(ite => parseInt(ite.id) === parseInt(item.empID))
      const docPart = {
          columns: [
            {
              // % width
              width: '35%',
              text: [
                { text: this.lang.Poslodavac + ' \n', fontSize: 12, bold: true },
                { text: this.comp.CompanyName + ' \n', fontSize: 12, bold: true },
                { text: (this.comp.CompanyStreet ? this.comp.CompanyStreet : ' ') + ' ' + (this.comp.CompanyBuildingNameNumber ? this.comp.CompanyBuildingNameNumber : ' ') + ' \n', fontSize: 8 },
                { text: (this.comp.CompanyPostcode ? this.comp.CompanyPostcode : ' ') + ' ' + (this.comp.CompanyCity ? this.comp.CompanyCity : ' ') + ' \n', fontSize: 8 },
                { text: this.lang.OIB + (this.comp.CompanyVATID ? this.comp.CompanyVATID : ' ') + ' \n', fontSize: 8 },
                { text: 'Žiro račun:' + (this.ana.bankAccountNr ? this.ana.bankAccountNr : ' ') + (this.ana.bankAccountName ? ' ' + this.ana.bankAccountName : ' ') + ' \n', fontSize: 8 },
                { text: this.lang.SIfraObracuna + (item.Month ? item.Month + '/' : '') + (item.Year ? item.Year + ', ('  : '') + (this.ana.Order ? this.ana.Order + ')' : '') , fontSize: 7 },
              ]
            },
            {
              // auto-sized columns have their widths based on their content
              width: '45%',
              text: [
                { text: this.lang.Zaposlenik + ' \n', fontSize: 12, bold: true },
                { text: (item.First ? item.First + ' ' : '') + (item.Last ? item.Last  : '') + ' \n', fontSize: 12, bold: true },
                { text: (empl && empl.PlaceOfLive ? empl.PlaceOfLive.trim() : ' ') +  ' \n', fontSize: 8 },
                { text: this.lang.OIB + (empl.VatID ? empl.VatID : ' ') + ' \n', fontSize: 8 },
                { text: this.lang.Racun + (item.Fin1bankAccountNr1 ? item.Fin1bankAccountNr1 : ' ') + (item.Fin1bankAccountName ? ' ' +item.Fin1bankAccountName : ' ') + ' \n', fontSize: 8 },
                { text: '      ' + (item.Fin1bankAccountNr2 ? item.Fin1bankAccountNr2 + '  ' : ' ') + (item.Fin1bankAccountNr3 ? ' ' + item.Fin1bankAccountNr3 : ' ') + ' \n', fontSize: 8 },
              ]
            },
            {
              // % width
              width: '20%',
              text: [     
                { text: this.lang.ObrazacIP + '\n', fontSize: 12, bold: true },            
                { text: this.lang.DatumIspisa + dayjs().format('DD.MM.YYYY, h:mm:ss a'), fontSize:8}
              ]   
            }
          ],
          // optional space between columns
          columnGap: 10
        }
        this.content.push(docPart)
    },
    addHeadMain(item) {

      const headLn = this.ana.FreeF7 && this.ana.FreeF7.length > 2 ? this.ana.FreeF7 : this.lang.ObracunPlaceZa
      const docPart = {
          columns: [
            {
              // % width
              width: '34%',
              text: [
                { text: ' \n', fontSize: 10, bold: true },
              ]
            },
            {
              // auto-sized columns have their widths based on their content
              width: '45%',
              text: [
                { text: headLn + ' ' + (item.Month ? item.Month + '/' : '') + (item.Year ? item.Year  : '') , fontSize: 12, bold: true },
              ]
            },
          ],
          // optional space between columns
          columnGap: 10
        }
        this.content.push(docPart)
    },
    addHoursHeader() {
      const docPart = {
          columns: [
            {
              // % width
              width: '45%',
              text: [
                { text: this.lang.VrstaRada + ' \n' , fontSize: 10,  },
              ]
            },
            {
              // auto-sized columns have their widths based on their content
              width: '15%',
              text: [
               // { text: (item.FinBruto0 > 0 ? item.FinWH + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: this.lang.Sati + '\n' , fontSize: 10, alignment: 'right' },
              ]
            },
            {
              // % width
              width: '15%',
              text: [
               // { text: (item.FinBruto0 > 0 ? this.formatPrice(item.FinWHvalue) + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: '% \n', fontSize: 10, alignment: 'right' },
              ]   
            },
            {
              // % width
              width: '15%',
              text: [                 
               // { text: (item.FinBruto0 > 0 ? this.formatPrice(item.FinBruto0) + ' \n' : ''), fontSize:10, alignment: 'right',},
                { text: this.lang.Iznos + '\n', fontSize:10, alignment: 'right',},
              ]   
            }
          ],
          // optional space between columns
          columnGap: 10
        }
        this.content.push(docPart)
    },
    addHours(item) {
      const docPart = {
          columns: [
            {
              // % width
              width: '45%',
              text: [
                //{ text: (item.FinBruto0 > 0 ? 'Ugovorena plaća' + ' \n' : '') , fontSize: 10,  },
                { text: (item.RedovanRadIznos > 0 ? this.lang.RedovanRad + ' \n' : '') , fontSize: 10,  },
                { text: (item.NocniRadIznos > 0 ? 'Noćni Rad' + ' \n' : '') , fontSize: 10,  },
                { text: (item.PrekovremeniRadIznos > 0 ? 'Prekovremeni Rad' + ' \n' : '') , fontSize: 10,  },
                { text: (item.NedjeljaRadIznos > 0 ? this.lang.Nedjelja + ' \n' : '') , fontSize: 10,  },
                { text: (item.BlagdanRadIznos > 0 ? this.lang.Blagdan + ' \n' : '') , fontSize: 10,  },
                { text: (item.TerenRadIznos > 0 ? this.lang.Teren + ' \n' : '') , fontSize: 10,  },
                { text: (item.PreraspodjelaRadaIznos > 0 ? this.lang.PreraspodjelaRada + ' \n' : '') , fontSize: 10,  },
                { text: (item.PripravnostIznos > 0 ? this.lang.Pripravnost + ' \n' : '') , fontSize: 10,  },
                { text: (item.DnevniOdmorIznos > 0 ? this.lang.DnevniOdmor + ' \n' : '') , fontSize: 10,  },
                { text: (item.TjedniOdmorIznos > 0 ? this.lang.TjedniOdmor + ' \n' : '') , fontSize: 10,  },
                { text: (item.NeradniBlagdanIznos > 0 ? 'Neradni Blagdan' + ' \n' : '') , fontSize: 10,  },
                { text: (item.GodisnjiOdmorIznos > 0 ? 'Godišnji Odmor' + ' \n' : '') , fontSize: 10,  },
                { text: (item.BolovanjeDo42Dana > 0 ? 'Bolovanje Do 42 Dana' + ' \n' : '') , fontSize: 10,  },
                { text: (item.BolovanjeOd42Dana > 0 ? 'Bolovanje - fond' + ' \n' : '') , fontSize: 10,  },
                { text: (item.Rodiljni > 0 ? 'Rodiljni dopust' + ' \n' : '') , fontSize: 10,  },
                { text: (item.PlaceniDopust > 0 ? 'Plaćeni dopust' + ' \n' : '') , fontSize: 10,  },
                { text: (item.NeplaceniDopust > 0 ? 'Neplaćeni dopust '+ ' \n' : '') , fontSize: 10,  },
                { text: (item.NenazocnostNaZahtjevRadnikaIznos > 0 ? 'Nenazočnost Na Zahtjev Radnika' + ' \n' : '') , fontSize: 10,  },
                { text: (item.ZastojKrivnjomPoslodavcaIznos > 0 ? 'Zastoj Krivnjom Poslodavca' + ' \n' : '') , fontSize: 10,  },
                { text: (item.StrajkIznos > 0 ? 'Štrajk' + ' \n' : '') , fontSize: 10,  },
                { text: (item.LockoutIznos > 0 ? 'Lockout' + ' \n' : '') , fontSize: 10,  },
                { text: (item.VrijemeMirovanjaRadnogOdnosaIznos > 0 ? 'Vrijeme Mirovanja Radnog Odnosa' + ' \n' : '') , fontSize: 10,  },
                { text: (item.Dodatno1 > 0 ? item.Dodatno1Opis + ' \n' : '') , fontSize: 10,  },
                { text: (item.Dodatno2 > 0 ? item.Dodatno2Opis + ' \n' : '') , fontSize: 10,  },
                { text: (item.UkupnoIznos > 0 ? 'UKUPNO' + ' \n' : '') , fontSize: 10,  },
              ]
            },
            {
              // auto-sized columns have their widths based on their content
              width: '15%',
              text: [
               // { text: (item.FinBruto0 > 0 ? item.FinWH + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.RedovanRadIznos > 0 ? item.RedovanRad + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.NocniRadIznos > 0 ? item.NocniRad + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.PrekovremeniRadIznos > 0 ? item.PrekovremeniRad + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.NedjeljaRadIznos > 0 ? item.NedjeljaRad + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.BlagdanRadIznos > 0 ? item.BlagdanRad + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.TerenRadIznos > 0 ? item.TerenRad + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.PreraspodjelaRadaIznos > 0 ? item.PreraspodjelaRada + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.PripravnostIznos > 0 ? item.Pripravnost + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.DnevniOdmorIznos > 0 ? item.DnevniOdmor + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.TjedniOdmorIznos > 0 ? item.TjedniOdmor + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.NeradniBlagdanIznos > 0 ? item.NeradniBlagdan + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.GodisnjiOdmorIznos > 0 ? item.GodisnjiOdmor + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.BolovanjeDo42Dana > 0 ? item.BolovanjeDo42Dana + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.BolovanjeOd42Dana > 0 ? item.BolovanjeOd42Dana + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.RodiljniIznos > 0 ? item.Rodiljni + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.PlaceniDopustIznos > 0 ? item.PlaceniDopust + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.NeplaceniDopustIznos > 0 ? item.NeplaceniDopust + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.NenazocnostNaZahtjevRadnikaIznos > 0 ? item.NenazocnostNaZahtjevRadnika + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.ZastojKrivnjomPoslodavcaIznos > 0 ? item.ZastojKrivnjomPoslodavca + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.StrajkIznos > 0 ? item.Strajk + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.LockoutIznos > 0 ? item.Lockout + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.VrijemeMirovanjaRadnogOdnosaIznos > 0 ? item.VrijemeMirovanjaRadnogOdnosa + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.Dodatno1Iznos > 0 ? item.Dodatno1 + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.Dodatno2Iznos > 0 ? item.Dodatno2 + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.UkupnoIznos > 0 ? item.Ukupno + ' \n' : ''), fontSize: 10, alignment: 'right' },
              ]
            },
            {
              // % width
              width: '15%',
              text: [
               // { text: (item.FinBruto0 > 0 ? this.formatPrice(item.FinWHvalue) + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.RedovanRadIznos > 0 ? this.formatPrice(item.RedovanRadPosto) + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.NocniRadIznos > 0 ? this.formatPrice(item.NocniRadPosto) + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.PrekovremeniRadIznos > 0 ? this.formatPrice(item.PrekovremeniRadPosto) + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.NedjeljaRadIznos > 0 ? this.formatPrice(item.NedjeljaRadPosto) + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.BlagdanRadIznos > 0 ? this.formatPrice(item.BlagdanRadPosto) + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.TerenRadIznos > 0 ? this.formatPrice(item.TerenRadPosto) + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.PreraspodjelaRadaIznos > 0 ? this.formatPrice(item.PreraspodjelaRadaPosto) + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.PripravnostIznos > 0 ? this.formatPrice(item.PripravnostPosto) + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.DnevniOdmorIznos > 0 ? this.formatPrice(item.DnevniOdmorPosto) + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.TjedniOdmorIznos > 0 ? this.formatPrice(item.TjedniOdmorPosto) + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.NeradniBlagdanIznos > 0 ? this.formatPrice(item.NeradniBlagdanPosto) + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.GodisnjiOdmorIznos > 0 ? this.formatPrice(item.GodisnjiOdmorPosto) + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.BolovanjeDo42DanaPosto > 0 ? this.formatPrice(item.BolovanjeDo42DanaPosto) + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.BolovanjeOd42DanaPosto > 0 ? this.formatPrice(item.BolovanjeOd42DanaPosto) + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.RodiljniIznos > 0 ? this.formatPrice(item.RodiljniPosto) + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.PlaceniDopustIznos > 0 ? this.formatPrice(item.PlaceniDopustPosto) + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.NeplaceniDopustIznos > 0 ? this.formatPrice(item.NeplaceniDopustPosto) + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.NenazocnostNaZahtjevRadnikaIznos > 0 ? this.formatPrice(item.NenazocnostNaZahtjevRadnikaPosto) + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.ZastojKrivnjomPoslodavcaIznos > 0 ? this.formatPrice(item.ZastojKrivnjomPoslodavcaPosto) + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.StrajkIznos > 0 ? this.formatPrice(item.StrajkPosto) + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.LockoutIznos > 0 ? this.formatPrice(item.LockoutPosto) + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.VrijemeMirovanjaRadnogOdnosaIznos > 0 ? this.formatPrice(item.VrijemeMirovanjaRadnogOdnosaPosto) + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.Dodatno1Iznos > 0 ? this.formatPrice(item.Dodatno1Posto) + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.Dodatno2Iznos > 0 ? this.formatPrice(item.Dodatno2Posto) + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.UkupnoIznos > 0 ?  ' \n' : ''), fontSize: 10, alignment: 'right' },
              ]   
            },
            {
              // % width
              width: '15%',
              text: [                 
               // { text: (item.FinBruto0 > 0 ? this.formatPrice(item.FinBruto0) + ' \n' : ''), fontSize:10, alignment: 'right',},
                { text: (item.RedovanRadIznos > 0 ? this.formatPrice(item.RedovanRadIznos) + ' \n' : ''), fontSize:10, alignment: 'right',},
                { text: (item.NocniRadIznos > 0 ? this.formatPrice(item.NocniRadIznos) + ' \n' : ''), fontSize:10, alignment: 'right',},
                { text: (item.PrekovremeniRadIznos > 0 ? this.formatPrice(item.PrekovremeniRadIznos) + ' \n' : ''), fontSize:10, alignment: 'right',},
                { text: (item.NedjeljaRadIznos > 0 ? this.formatPrice(item.NedjeljaRadIznos) + ' \n' : ''), fontSize:10, alignment: 'right',},
                { text: (item.BlagdanRadIznos > 0 ? this.formatPrice(item.BlagdanRadIznos) + ' \n' : ''), fontSize:10, alignment: 'right',},
                { text: (item.TerenRadIznos > 0 ? this.formatPrice(item.TerenRadIznos) + ' \n' : ''), fontSize:10, alignment: 'right',},
                { text: (item.PreraspodjelaRadaIznos > 0 ? this.formatPrice(item.PreraspodjelaRadaIznos) + ' \n' : ''), fontSize:10, alignment: 'right',},
                { text: (item.PripravnostIznos > 0 ? this.formatPrice(item.PripravnostIznos) + ' \n' : ''), fontSize:10, alignment: 'right',},
                { text: (item.DnevniOdmorIznos > 0 ? this.formatPrice(item.DnevniOdmorIznos) + ' \n' : ''), fontSize:10, alignment: 'right',},
                { text: (item.TjedniOdmorIznos > 0 ? this.formatPrice(item.TjedniOdmorIznos) + ' \n' : ''), fontSize:10, alignment: 'right',},
                { text: (item.NeradniBlagdanIznos > 0 ? this.formatPrice(item.NeradniBlagdanIznos) + ' \n' : ''), fontSize:10, alignment: 'right',},
                { text: (item.GodisnjiOdmorIznos > 0 ? this.formatPrice(item.GodisnjiOdmorIznos) + ' \n' : ''), fontSize:10, alignment: 'right',},
                { text: (item.BolovanjeDo42DanaIznos > 0 ? this.formatPrice(item.BolovanjeDo42DanaIznos) + ' \n' : ''), fontSize:10, alignment: 'right',},
                { text: (item.BolovanjeOd42Dana > 0 ?  ' \n' : '\n'), fontSize:10, alignment: 'right',},
                { text: (item.RodiljniIznos > 0 ? this.formatPrice(item.RodiljniIznos) + ' \n' : ''), fontSize:10, alignment: 'right',},
                { text: (item.PlaceniDopustIznos > 0 ? this.formatPrice(item.PlaceniDopustIznos) + ' \n' : ''), fontSize:10, alignment: 'right',},
                { text: (item.NeplaceniDopustIznos > 0 ? this.formatPrice(item.NeplaceniDopustIznos) + ' \n' : ''), fontSize:10, alignment: 'right',},
                { text: (item.NenazocnostNaZahtjevRadnikaIznos > 0 ? this.formatPrice(item.NenazocnostNaZahtjevRadnikaIznos) + ' \n' : ''), fontSize:10, alignment: 'right',},
                { text: (item.ZastojKrivnjomPoslodavcaIznos > 0 ? this.formatPrice(item.ZastojKrivnjomPoslodavcaIznos) + ' \n' : ''), fontSize:10, alignment: 'right',},
                { text: (item.StrajkIznos > 0 ? this.formatPrice(item.StrajkIznos) + ' \n' : ''), fontSize:10, alignment: 'right',},
                { text: (item.LockoutIznos > 0 ? this.formatPrice(item.LockoutIznos) + ' \n' : ''), fontSize:10, alignment: 'right',},
                { text: (item.VrijemeMirovanjaRadnogOdnosaIznos > 0 ? this.formatPrice(item.VrijemeMirovanjaRadnogOdnosaIznos) + ' \n' : ''), fontSize:10, alignment: 'right',},
                { text: (item.Dodatno1Iznos > 0 ? this.formatPrice(item.Dodatno1Iznos) + ' \n' : ''), fontSize:10, alignment: 'right',},
                { text: (item.Dodatno2Iznos > 0 ? this.formatPrice(item.Dodatno2Iznos) + ' \n' : ''), fontSize:10, alignment: 'right',},
                { text: (item.UkupnoIznos > 0 ? this.formatPrice(item.UkupnoIznos) + ' \n' : ''), fontSize:10, alignment: 'right',},
              ]   
            }
          ],
          // optional space between columns
          columnGap: 10
        }
        this.content.push(docPart)
    },
    addMainHeader() {
      const docPart = {
          columns: [
            {
              // % width
              width: '45%',
              text: [
                { text: '.. \n' , fontSize: 10,  },
              ]
            },
            {
              // auto-sized columns have their widths based on their content
              width: '15%',
              text: [
               // { text: (item.FinBruto0 > 0 ? item.FinWH + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: '. \n' , fontSize: 10, alignment: 'right' },
              ]
            },
            {
              // % width
              width: '15%',
              text: [
               // { text: (item.FinBruto0 > 0 ? this.formatPrice(item.FinWHvalue) + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: '.. \n', fontSize: 10, alignment: 'right' },
              ]   
            },
            {
              // % width
              width: '15%',
              text: [                 
               // { text: (item.FinBruto0 > 0 ? this.formatPrice(item.FinBruto0) + ' \n' : ''), fontSize:10, alignment: 'right',},
                { text: 'Iznos \n', fontSize:10, alignment: 'right',},
              ]   
            }
          ],
          // optional space between columns
          columnGap: 10
        }
        this.content.push(docPart)
    },
    addMain1(item) {
      const haveBrutoAddition1 = parseFloat(item.FinKoef1) 
      const haveBrutoAddition2 = parseFloat(item.FinKoef2)
      const haveBrutoAddition = haveBrutoAddition1 + haveBrutoAddition2
      let Fin1 = (item.FinFrom1_Desc && item.FinFrom1_Desc.trim() === 'Doprinos za mirovinsko osiguranje 15%' && this.printDE) ? 'Beitrag zur Rentenversicherung 15%' : 'Doprinos za mirovinsko osiguranje 15%'
      Fin1 = (item.FinFrom1_Desc && item.FinFrom1_Desc.trim() === 'Doprinos za MIOI stup-gener.solidar. 20%' && this.printDE) ? 'Beitrag zur Rentenversicherung 20%' : 'Doprinos za MIOI stup-gener.solidar. 20%'
      const Fin2 = (item.FinFrom2_Desc && item.FinFrom2_Desc.trim() === 'Doprinos za MIO na temelju indiv. kapital. štednje 5%' && this.printDE) ? 'Rentenbeitrag auf Basis der ind.akt.Ersparnisse 5%' : 'Doprinos za MIO na temelju indiv. kapital. štednje 5%'
      const docPart = {
          columns: [
            {
              // % width
              width: '45%',
              text: [
                { text: (item.FinBruto1 > 0 ? this.lang.Bruto1 + ' \n' : '') , fontSize: 10,  },
                { text: (haveBrutoAddition1 > 0 ? (item.FinKoef1Desc)  + ' \n' : '') , fontSize: 8,  },
                { text: (haveBrutoAddition2 > 0 ? (item.FinKoef2Desc) + ' \n' : '') , fontSize: 8,  },
                { text: (haveBrutoAddition > 0 ? this.lang.Bruto2 + ' \n' : '') , fontSize: 10,  },
                { text: (item.FinFrom1_Amount > 0 ? this.lang.SalFrom + ' \n' : '') , fontSize: 10,  },
                { text: (item.FinFrom1_Amount > 0 ? Fin1 + ' \n' : '') , fontSize: 10,  },
                { text: (item.FinFrom2_Amount > 0 ? Fin2 + ' \n' : '') , fontSize: 10,  },
                { text: (item.FinFrom3_Amount > 0 ? item.FinFrom3_Desc + ' \n' : '') , fontSize: 10,  },
                { text: (item.FinFrom4_Amount > 0 ? item.FinFrom4_Desc + ' \n' : '') , fontSize: 10,  },
                { text: (item.FinNeto1ls > 0 ? this.lang.FinNeto1ls + ' \n' : '') , fontSize: 10,  },
              ]
            },
            {
              // auto-sized columns have their widths based on their content
              width: '15%',
              text: [
               // { text: (item.FinBruto0 > 0 ? item.FinWH + ' \n' : ''), fontSize: 10, alignment: 'right' },
                { text: (item.FinBruto1 > 0 ? ' \n' : '') , fontSize: 10,  },
                { text: (haveBrutoAddition1 > 0 ? ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (haveBrutoAddition2 > 0 ? ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (haveBrutoAddition > 0 ? ' \n' : '') , fontSize: 10,  },
                { text: (item.FinFrom1_Amount > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.FinFrom1_Amount > 0 ? this.formatPrice(item.FinFrom1_Percent) + '% \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinFrom2_Amount > 0 ? this.formatPrice(item.FinFrom2_Percent) + '% \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinFrom3_Amount > 0 ? this.formatPrice(item.FinFrom3_Percent) + '% \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinFrom4_Amount > 0 ? this.formatPrice(item.FinFrom4_Percent) + '% \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinNeto1ls > 0 ?  ' \n' : '') , fontSize: 10,  },
              ]
            },
            {
              // % width
              width: '15%',
              text: [
                { text: (item.FinBruto1 > 0 ? ' \n' : '') , fontSize: 10,  },
                { text: (haveBrutoAddition1 > 0 ? ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (haveBrutoAddition2 > 0 ?  ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (haveBrutoAddition > 0 ? ' \n' : '') , fontSize: 10,  },
                { text: (item.FinFrom1_Amount > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.FinFrom1_Amount > 0 ? this.formatPrice(item.FinFrom1_Amount) + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinFrom2_Amount > 0 ? this.formatPrice(item.FinFrom2_Amount) + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinFrom3_Amount > 0 ? this.formatPrice(item.FinFrom3_Amount) + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinFrom4_Amount > 0 ? this.formatPrice(item.FinFrom4_Amount) + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinNeto1ls > 0 ?  ' \n' : '') , fontSize: 10,  },
              ]   
            },
            {
              // % width
              width: '15%',
              text: [                 
                { text: (item.FinBruto1 > 0 ? this.formatPrice(item.FinBruto1) + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (haveBrutoAddition1 > 0 ?  this.formatPrice(item.FinKoef1) + ' \n' : '') , fontSize: 10,  },
                { text: (haveBrutoAddition2 > 0 ?  this.formatPrice(item.FinKoef2) + ' \n' : '') , fontSize: 10,  },
                { text: (haveBrutoAddition > 0 ? this.formatPrice(item.FinBruto2) + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinFrom1_Amount > 0 ? ' \n' : '') , fontSize: 10,  },
                { text: (item.FinFrom1_Amount > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.FinFrom2_Amount > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.FinFrom3_Amount > 0 ? ' \n' : '') , fontSize: 10,  },
                { text: (item.FinFrom4_Amount > 0 ? ' \n' : '') , fontSize: 10,  },
                { text: (item.FinNeto1ls > 0 ? this.formatPrice(item.FinNeto1ls) + ' \n' : '') , fontSize: 10, alignment: 'right', },
              ]   
            }
          ],
          // optional space between columns
          columnGap: 10
        }
        this.content.push(docPart)
    },
    addMain2(item) {
      const taxDesc = (item.FinTaxOnTaxPer > 0 ? this.lang.PorezPrirezUkupno : this.lang.PorezUkupno)  + (item.FinTaxOnTaxCityName ? item.FinTaxOnTaxCityName : '')
      const faktorFree = (item.FinFree1Indeks ? item.FinFree1Indeks : 0) + (item.FinFree2Indeks ? item.FinFree2Indeks : 0) + (item.FinFree3Indeks ? item.FinFree3Indeks : 0) + (item.FinFree4In ? item.FinFree4In : 0)
      const freeTax = this.lang.NeoporeziviOdbitakFaktor + faktorFree + ' = ' + this.formatPrice(item.FinFreeTotalToUse) +  ')'
      const freeTaxAm =  item.FinNeto1ls < item.FinFreeTotalToUse ? item.FinNeto1ls : item.FinNeto1ls - item.FinFreeTotalToUse
      const taxBase =  ((item.FinNeto1ls - item.FinFreeTotalToUse) > 0 ? (item.FinNeto1ls - item.FinFreeTotalToUse) : 0)
      const docPart = {
          columns: [
            {
              // % width
              width: '45%',
              text: [

                { text: (item.FinFreeTotalToUse > 0 ? freeTax + ' \n' : '') , fontSize: 10,  },
                { text: (item.FinFreeTotalToUse > 0 ? this.lang.PoreznaOsnovica + ' \n' : '') , fontSize: 10,  },
                { text: (item.FinTax1Am > 0 ? this.lang.CityTaxId + ' \n' : '') , fontSize: 10,  },
                { text: (item.FinTax1Am > 0 ? ' ' + this.lang.Tax20h + this.formatPrice(item.FinTax1Per) +  '% \n' : '') , fontSize: 10,  },
                { text: (item.FinTax2Am > 0 ? ' ' + this.lang.Tax30h + this.formatPrice(item.FinTax2Per) +  '% \n' : '') , fontSize: 10,  },
                { text: (item.FinTax2Am > 0 ? this.lang.Tax30h + ' \n' : '') , fontSize: 10,  },
                { text: (item.FinTax3To !== 0 ?'Godišnja razlika poreza \n' : '') , fontSize: 10,  },
                { text: (item.FinTaxTotal > 0 ? taxDesc + ' \n' : '') , fontSize: 10,  },
                { text: (item.FinNeto2 > 0 ? this.lang.Neto + ' \n' : '') , fontSize: 10,  },
              ]
            },
            {
              // auto-sized columns have their widths based on their content
              width: '15%',
              text: [

                { text: (item.FinFreeTotalToUse > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.FinFreeTotalToUse > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.FinTax1Am > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.FinTax1Am > 0 ? this.formatPrice(item.FinTax1From) + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinTax2Am > 0 ? this.formatPrice(item.FinTax2From) + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinTax3To !== 0 ? ' \n' : '') , fontSize: 10,  },
                { text: (item.FinTaxOnTaxAm > 0 ? this.formatPrice(item.FinTaxOnTaxPer) + '% \n' : '') , fontSize: 10, alignment: 'right',  },
                { text: (item.FinTaxTotal > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.FinNeto2 > 0 ? ' \n' : '') , fontSize: 10,  },
              ]
            },
            {
              // % width
              width: '15%',
              text: [

                { text: (item.FinFreeTotalToUse > 0 ? this.formatPrice(freeTaxAm) + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinFreeTotalToUse > 0 ? this.formatPrice(taxBase) + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinTax1Am > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.FinTax1Am > 0 ? this.formatPrice(item.FinTax1Am) + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinTax2Am > 0 ? this.formatPrice(item.FinTax2Am) + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinTax3To !== 0 ? this.formatPrice(item.FinTax3To) + ' \n' : '') , fontSize: 10,  },
                { text: (item.FinTaxOnTaxAm > 0 ? this.formatPrice(item.FinTaxOnTaxAm) + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinTaxTotal > 0 ?  ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinNeto2 > 0 ? ' \n' : '') , fontSize: 10,  },
              ]   
            },
            {
              // % width
              width: '15%',
              text: [                 
                { text: (item.FinFreeTotalToUse > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.FinFreeTotalToUse > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.FinTax1Am > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.FinTax1Am > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.FinTax2Am > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.FinTaxOnTaxAm > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.FinTaxTotal > 0 ? this.formatPrice(item.FinTaxTotal) + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinNeto2 > 0 ? this.formatPrice(item.FinNeto2) + ' \n' : '') , fontSize: 10, alignment: 'right', },
              ]   
            }
          ],
          // optional space between columns
          columnGap: 10
        }
        this.content.push(docPart)
    },
    addMain2_2024(item) {
      const taxDesc = (item.FinTaxOnTaxPer > 0 ? this.lang.PorezPrirezUkupno : this.lang.PorezUkupno)  + (item.FinTaxOnTaxCityName ? item.FinTaxOnTaxCityName : '')
      const faktorFree = (item.FinFree1Indeks ? item.FinFree1Indeks : 0) + (item.FinFree2Indeks ? item.FinFree2Indeks : 0) + (item.FinFree3Indeks ? item.FinFree3Indeks : 0) + (item.FinFree4In ? item.FinFree4In : 0)
      const freeTax = this.lang.NeoporeziviOdbitakFaktor + faktorFree + ' = ' + this.formatPrice(item.FinFreeTotalToUse) +  ')'
      const freeTaxAm =  item.FinNeto1ls < item.FinFreeTotalToUse ? item.FinNeto1ls : item.FinNeto1ls - item.FinFreeTotalToUse
      const taxBase =  ((item.FinNeto1ls - item.FinFreeTotalToUse) > 0 ? (item.FinNeto1ls - item.FinFreeTotalToUse) : 0)
      const docPart = {
          columns: [
            {
              // % width
              width: '45%',
              text: [

                { text: (item.FinFreeTotalToUse > 0 ? freeTax + ' \n' : '') , fontSize: 10,  },
                { text: (item.FinFreeTotalToUse > 0 ? this.lang.PoreznaOsnovica + ' \n' : '') , fontSize: 10,  },
                { text: (item.FinTax1Am > 0 ? this.lang.CityTaxId + ' \n' : '') , fontSize: 10,  },
                { text: ('Niža stopa % \n') , fontSize: 10,  },
                { text: ('Viša stopa % \n' ) , fontSize: 10,  },
                { text: (item.FinTax3To !== 0 ?'Godišnja razlika poreza \n' : '') , fontSize: 10,  },
                { text: ('Porez ukupno €= \n') , fontSize: 10,  },
                { text: (item.FinNeto2 > 0 ? this.lang.Neto + ' \n' : '') , fontSize: 10,  },
              ]
            },
            {
              // auto-sized columns have their widths based on their content
              width: '15%',
              text: [

                { text: (item.FinFreeTotalToUse > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.FinFreeTotalToUse > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.FinTax1Am > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (this.formatPrice(item.FinTax1Per) + ' \n') , fontSize: 10, alignment: 'right', },
                { text: (this.formatPrice(item.FinTax2Per) + ' \n') , fontSize: 10, alignment: 'right', },
                { text: (item.FinTax3To !== 0 ? ' \n' : '') , fontSize: 10,  },
                { text: (item.FinTaxTotal > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.FinNeto2 > 0 ? ' \n' : '') , fontSize: 10,  },
              ]
            },
            {
              // % width
              width: '15%',
              text: [

                { text: (item.FinFreeTotalToUse > 0 ? this.formatPrice(freeTaxAm) + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinFreeTotalToUse > 0 ? this.formatPrice(taxBase) + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinTax1Am > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (this.formatPrice(item.FinTax1Am) + ' \n') , fontSize: 10, alignment: 'right', },
                { text: (this.formatPrice(item.FinTax2Am) + ' \n') , fontSize: 10, alignment: 'right', },
                { text: (item.FinTax3To !== 0 ? this.formatPrice(item.FinTax3To) + ' \n' : '') , fontSize: 10,  },
                { text: (item.FinTaxTotal > 0 ?  ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinNeto2 > 0 ? ' \n' : '') , fontSize: 10,  },
              ]   
            },
            {
              // % width
              width: '15%',
              text: [                 
                { text: (item.FinFreeTotalToUse > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.FinFreeTotalToUse > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.FinTax1Am > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: ( ' \n' ) , fontSize: 10,  },
                { text: ( ' \n' ) , fontSize: 10,  },
                { text: (item.FinTaxTotal > 0 ? this.formatPrice(item.FinTaxTotal) + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinNeto2 > 0 ? this.formatPrice(item.FinNeto2) + ' \n' : '') , fontSize: 10, alignment: 'right', },
              ]   
            }
          ],
          // optional space between columns
          columnGap: 10
        }
        this.content.push(docPart)
    },
    addMain3(item) {
      const haveAddition = item.FinAdd1_Amount + item.FinAdd2_Amount + item.FinAdd3_Amount + item.FinAdd4_Amount
      const bank1 = this.lang.IsplataRedovanRacun + (item.Fin1bankAccountId ? ' ' + item.Fin1bankAccountId : '') + (item.Fin1bankAccountNr1 ? ' ' + item.Fin1bankAccountNr1 : '') + (item.Fin1bankAccountNr2 ? ' ' + item.Fin1bankAccountNr2 : '') + (item.Fin1bankAccountNr3 ? ' ' + item.Fin1bankAccountNr3 : '') + ', Iznos EUR: ' + this.formatPrice(item.Fin1bankAmount)
      const bank2 = this.lang.IsplataZasticeniRacun + (item.Fin2bankAccountId ? ' ' + item.Fin2bankAccountId : '') + (item.Fin2bankAccountNr1 ? ' ' + item.Fin2bankAccountNr1 : '') + (item.Fin2bankAccountNr2 ? ' ' + item.Fin2bankAccountNr2 : '') + (item.Fin2bankAccountNr3 ? ' ' + item.Fin2bankAccountNr3 : '') + ', Iznos EUR: ' + this.formatPrice(item.Fin2bankAmount)
      const docPart = {
          columns: [
            {
              // % width
              width: '45%',
              text: [
                { text: (haveAddition > 0 ? this.lang.NetoAddDesc + ' \n' : '') , fontSize: 10,  },
                { text: (item.FinAdd1_Amount > 0 ? (item.FinAdd1_Desc ? item.FinAdd1_Desc : '') + ' \n' : '') , fontSize: 10,  },
                { text: (item.FinAdd2_Amount > 0 ? (item.FinAdd2_Desc ? item.FinAdd2_Desc : '') + ' \n' : '') , fontSize: 10,  },
                { text: (item.FinAdd3_Amount > 0 ? (item.FinAdd3_Desc ? item.FinAdd3_Desc : '') + ' \n' : '') , fontSize: 10,  },
                { text: (item.FinAdd4_Amount > 0 ? (item.FinAdd4_Desc ? item.FinAdd4_Desc : '') + ' \n' : '') , fontSize: 10   },
                { text: (item.FinAdd5_Amount > 0 ? (item.FinAdd5_Desc ? item.FinAdd5_Desc : '') + ' \n' : '') , fontSize: 10   },
                { text: (item.NaHZZOsifra && item.NaHZZOsifra.length>0 ?  'Bolovanje - fond \n' : '') , fontSize: 10   },
                { text: (item.Fin1LoanBankAmount > 0 ? item.Fin1Loan + (item.Fin1LoanBankAccountName ? item.Fin1LoanBankAccountName: '') + ' \n' : '') , fontSize: 10,  },
                { text: (item.Fin2LoanBankAmount > 0 ? item.Fin2Loan + (item.Fin2LoanBankAccountName ? item.Fin2LoanBankAccountName: '') +' \n' : '') , fontSize: 10,  },
                { text: (item.Fin3LoanBankAmount > 0 ? item.Fin3Loan + (item.Fin3LoanBankAccountName ? item.Fin3LoanBankAccountName: '') +' \n' : '') , fontSize: 10,  },
                { text: (item.FinNeto3 > 0 ? this.lang.FinNeto3 + ' \n' : '') , fontSize: 12, bold: true   },
                { text: (item.Fin1bankAmount > 0 ? bank1 + ' \n' : '') , fontSize: 9,  },
                { text: (item.Fin2bankAmount > 0 ? bank2 + ' \n' : '') , fontSize: 9,  },
              ]
            },
            {
              // auto-sized columns have their widths based on their content
              width: '15%',
              text: [
                { text: (haveAddition > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.FinAdd1_Amount > 0 ? 'Joppd: ' + item.FinAdd1_JoppId + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinAdd2_Amount > 0 ? 'Joppd: ' + item.FinAdd2_JoppId + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinAdd3_Amount > 0 ? 'Joppd: ' + item.FinAdd3_JoppId + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinAdd4_Amount > 0 ? 'Joppd: ' + item.FinAdd4_JoppId + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinAdd5_Amount > 0 ? 'Joppd: ' + item.FinAdd5_JoppId + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.NaHZZOsifra && item.NaHZZOsifra.length>0 ?  ' \n' : '') , fontSize: 10   },
                { text: (item.Fin1LoanBankAmount > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.Fin2LoanBankAmount > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.Fin3LoanBankAmount > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.FinNeto3 > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.Fin1bankAmount > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.Fin2bankAmount > 0 ?  ' \n' : '') , fontSize: 10,  },

              ]
            },
            {
              // % width
              width: '15%',
              text: [

                { text: (haveAddition > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.FinAdd1_Amount > 0 ? this.formatPrice(item.FinAdd1_Amount) + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinAdd2_Amount > 0 ? this.formatPrice(item.FinAdd2_Amount) + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinAdd3_Amount > 0 ? this.formatPrice(item.FinAdd3_Amount) + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinAdd4_Amount > 0 ? this.formatPrice(item.FinAdd4_Amount) + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinAdd5_Amount > 0 ? this.formatPrice(item.FinAdd5_Amount) + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.NaHZZOsifra && item.NaHZZOsifra.length>0 ?  ' \n' : '') , fontSize: 10   },
                { text: (item.Fin1LoanBankAmount > 0 ? this.formatPrice(item.Fin1LoanBankAmount) + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.Fin2LoanBankAmount > 0 ? this.formatPrice(item.Fin2LoanBankAmount) + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.Fin3LoanBankAmount > 0 ? this.formatPrice(item.Fin3LoanBankAmount) + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinNeto3 > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.Fin1bankAmount > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.Fin2bankAmount > 0 ?  ' \n' : '') , fontSize: 10,  },
              ]   
            },
            {
              // % width
              width: '15%',
              text: [                 
                { text: (haveAddition > 0 ?   ' \n' : '') , fontSize: 10,  },
                { text: (item.FinAdd1_Amount > 0 ? ' \n' : '') , fontSize: 10,  },
                { text: (item.FinAdd2_Amount > 0 ? ' \n' : '') , fontSize: 10,  },
                { text: (item.FinAdd3_Amount > 0 ? ' \n' : '') , fontSize: 10,  },
                { text: (item.FinAdd4_Amount > 0 ? ' \n' : '') , fontSize: 10,  },
                { text: (item.FinAdd5_Amount > 0 ? ' \n' : '') , fontSize: 10,  },
                { text: (item.NaHZZOsifra && item.NaHZZOsifra.length>0 ?  this.formatPrice(item.NaHZZOiznos) + ' \n' : '') , fontSize: 10   },
                { text: (item.Fin1LoanBankAmount > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.Fin2LoanBankAmount > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.Fin3LoanBankAmount > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.FinNeto3 > 0 ? this.formatPrice(item.FinNeto3) + ' \n' : '') , fontSize: 12, alignment: 'right', bold: true },
                { text: (item.Fin1bankAmount > 0 ? ' \n' : '') , fontSize: 10,  },
                { text: (item.Fin2bankAmount > 0 ? ' \n' : '') , fontSize: 10,  },
              ]   
            }
          ],
          // optional space between columns
          columnGap: 10
        }
        this.content.push(docPart)
    },
    addMain4(item) {
      // const haveAddition = item.FinAdd1_Amount + item.FinAdd2_Amount + item.FinAdd3_Amount + item.FinAdd4_Amount
      // const haveBrutoAddition = item.FinKoef1 + item.FinKoef2
      // const bank1 = 'Isplata redovan račun:' + (item.Fin1bankAccountId ? ' ' + item.Fin1bankAccountId : '') + (item.Fin1bankAccountNr1 ? ' ' + item.Fin1bankAccountNr1 : '') + (item.Fin1bankAccountNr2 ? ' ' + item.Fin1bankAccountNr2 : '') + (item.Fin1bankAccountNr3 ? ' ' + item.Fin1bankAccountNr3 : '')
      // const bank2 = 'Isplata zaštićeni račun:' + (item.Fin2bankAccountId ? ' ' + item.Fin2bankAccountId : '') + (item.Fin2bankAccountNr1 ? ' ' + item.Fin2bankAccountNr1 : '') + (item.Fin2bankAccountNr2 ? ' ' + item.Fin2bankAccountNr2 : '') + (item.Fin2bankAccountNr3 ? ' ' + item.Fin2bankAccountNr3 : '')
      // const taxDesc = (item.FinTaxOnTaxPer > 0 ? 'Porez + prirez ukupno:' : 'Porez ukupno:')  + (item.FinTaxOnTaxCityName ? item.FinTaxOnTaxCityName : '')
      // const faktorFree = (item.FinFree1Indeks ? item.FinFree1Indeks : 0) + (item.FinFree2Indeks ? item.FinFree2Indeks : 0) + (item.FinFree3Indeks ? item.FinFree3Indeks : 0) + (item.FinFree4In ? item.FinFree4In : 0)
      // const freeTax = 'Neoporezivi odbitak (Faktor: ' + faktorFree + ' = ' + this.formatPrice(item.FinFreeTotalToUse) +  ')'
      // const freeTaxAm =  item.FinNeto1ls < item.FinFreeTotalToUse ? item.FinNeto1ls : item.FinNeto1ls - item.FinFreeTotalToUse
      // const taxBase =  ((item.FinNeto1ls - item.FinFreeTotalToUse) > 0 ? (item.FinNeto1ls - item.FinFreeTotalToUse) : 0)

      const Fin1 = (item.FinTo1_Desc && item.FinTo1_Desc.trim() === 'Doprinos za zdravstveno osiguranje - 16,5%' && this.printDE) ? 'Beitrag zur Krankenversicherung - 16,5%' : 'Doprinos za zdravstveno osiguranje - 16,5%' 
      const docPart = {
          columns: [
            {
              // % width
              width: '45%',
              text: [

                { text: (item.FinTo1_Amount > 0 ? this.lang.SalToHead + ' \n' : '') , fontSize: 10,  },
                { text: (item.FinTo1_Amount > 0 ? Fin1 + ' \n' : '') , fontSize: 10,  },
                { text: (item.FinTo2_Amount > 0 ? item.FinTo2_Desc + ' \n' : '') , fontSize: 10,  },
                { text: (item.FinTo3_Amount > 0 ? item.FinTo3_Desc + ' \n' : '') , fontSize: 10,  },
                { text: (item.FinBruto3 > 0 ? this.lang.Bruto3 + ' \n' : '') , fontSize: 10,  },
              ]
            },
            {
              // auto-sized columns have their widths based on their content
              width: '15%',
              text: [

                { text: (item.FinTo1_Amount > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.FinTo1_Amount > 0 ? this.formatPrice(item.FinTo1_Percent) + '% \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinTo2_Amount > 0 ? this.formatPrice(item.FinTo2_Percent) + '% \n' : '') , fontSize: 10, alignment: 'right',},
                { text: (item.FinTo3_Amount > 0 ? this.formatPrice(item.FinTo3_Percent) + '% \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinBruto3 > 0 ?  ' \n' : '') , fontSize: 10,  },
              ]
            },
            {
              // % width
              width: '15%',
              text: [
                { text: (item.FinTo1_Amount > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.FinTo1_Amount > 0 ? this.formatPrice(item.FinTo1_Amount) + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinTo2_Amount > 0 ? this.formatPrice(item.FinTo2_Amount) + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinTo3_Amount > 0 ? this.formatPrice(item.FinTo3_Amount) + ' \n' : '') , fontSize: 10, alignment: 'right', },
                { text: (item.FinBruto3 > 0 ?  ' \n' : '') , fontSize: 10,  },
              ]   
            },
            {
              // % width
              width: '15%',
              text: [                 
                { text: (item.FinTo1_Amount > 0 ?  ' \n' : '') , fontSize: 10,  },
                { text: (item.FinTo1_Amount > 0 ? ' \n' : '') , fontSize: 10,  },
                { text: (item.FinTo2_Amount > 0 ? ' \n' : '') , fontSize: 10,  },
                { text: (item.FinTo3_Amount > 0 ? ' \n' : '') , fontSize: 10,  },
                { text: (item.FinBruto3 > 0 ? this.formatPrice(item.FinBruto3) + ' \n' : '') , fontSize: 10, alignment: 'right', },
              ]   
            }
          ],
          // optional space between columns
          columnGap: 10
        }
        this.content.push(docPart)
    },
    addTotal () {
      const docPart = {
          columns: [
            {
              width: '5%',
              fontSize: 8,
              text: ''
            },
            {
              width: '32%',
              fontSize: 8,
              text: ''
            },
            {
              width: '9%',
              fontSize: 8,
              text: ' '
            },
            {
              width: '9%',
              fontSize: 8,
              text: ' '
            },
            {
              width: '9%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total1) + '\n', fontSize: 8
            },
            {
              width: '9%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total2) + '\n', fontSize: 8
            },
            {
              width: '9%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total3) + '\n', fontSize: 8
            }
          ]
        }
      this.content.push(docPart)
      
    },
    addTotal10 () {
      const docPart = {
          columns: [
            {
              width: '5%',
              fontSize: 8,
              text: ''
            },
            {
              width: '10%',
              fontSize: 8,
              text: ''
            },
            {
              width: '22%',
              fontSize: 8,
              text: ''
            },
            {
              width: '10%',
              fontSize: 8,
              text: ''
            },
            {
              width: '9%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total1) + '\n', fontSize: 8
            },
            {
              width: '9%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total2) + '\n', fontSize: 8
            },
            {
              width: '9%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total3) + '\n', fontSize: 8
            },
            {
              width: '9%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total10) + '\n', fontSize: 8
            },
            {
              width: '9%',
              alignment: 'right',
              text: this.formatPrice(this.ana.Total4) + '\n', fontSize: 8
            }
          ]
        }
      this.content.push(docPart)
      
    },

    addSignature () {
      const docPart = {
        columns: [
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: ' '
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                 // { text: '____________________ \n', fontSize: 8, alignment: 'center' }
                ]
              },
              {
                width: '5%',
                text: [
                  { text: ' ' }
                ]
              },
              {
                width: '25%',
                text: [
                  { text: ' \n', fontSize: 10 },
                  { text: '____________________  \n', fontSize: 8, alignment: 'center' }
                ]
              }
            ]
          }
      this.content.push(docPart)
    },

    addEmpty () {
      const docPart = {
        
          }
      this.content.push(docPart)
    },

  },
  computed: {
  },
  watch: {
  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slide-fade-enter-active {
  transition: all 1.3s ease;
}
.slide-fade-leave-active {
  transition: all 1.8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
