const docHR = {
  Salarie: 'Plaće',
  SalarieList: 'Plaće lista',
  SalarieDetail: 'Plaća',
  Create: 'Kreiraj',
  Delete: 'Obriši',
  Order: 'Redni broj',
  Month: 'Mjesec',
  Year: 'Godina',
  NumberOfEmp: 'Broj zaposlenih',
  HoursNormal: 'Radni sati',
  HoursHoliday: 'Praznik',
  DateOpen: 'Datum kreiranja',
  Locked: 'Zaključano',
  LockedDate: 'Zaključano datuma',
  Question1: 'Upit1',
  Question2: 'Upit 2',
  hashtag: '#',
  eemail: 'email',
  First: 'Ime',
  Last: 'Prezime',
  VatID: 'OIB',
  FinBruto1: 'Bruto 1 €',
  FinBruto2: 'Bruto 2 €',
  FinNeto2: 'Neto 2 €',
  FinBruto3: 'Bruto 3 €',
  DeleteMonth: 'Obriši',
  Poslodavac: 'Poslodavac',
  SIfraObracuna: 'Šifra obračuna: ',
  Zaposlenik:'Zaposlenik:',
  OIB: 'OIB:',
  Racun: 'Račun:',
  ObrazacIP: 'Obrazac IP',
  DatumIspisa:'Datum ispisa:',
  ObracunPlaceZa: 'Obračun plaće za ',
  VrstaRada: 'Vrsta rada ',
  Sati: 'Sati',
  Iznos:'Iznos',
  Bruto1:' Bruto plaća 1 €',
  Add1:'Prvi dodatak na bruto 1',
  Add2:'Drugi dodatak na bruto 1',
  Bruto2: 'Bruto plaća 2 €',
  SalFrom: 'Doprinosi iz plaće',
  SalFrom1: 'Doprinos iz',
  SalFrom1Desc: 'Doprinos opis',
  SalFrom1Per: '%',
  SalFrom2: 'Doprinos iz',
  SalFrom2Desc: 'Doprinos opis',
  SalFrom2Per: '%',
  SalFrom2Amount: 'Iznos',
  FinNeto1ls: 'Dohodak €',
  Deduction: 'Osobni odbitak €',
  DeductionKids: 'Odbitak za djecu',
  DeductionFamily: 'Odbitak za uzdržavane članove obitelji',
  DeductionDisablity: 'Invaliditet',
  DeductionFactor: 'Faktor',
  DeductionAmount: 'Iznos odbitka',
  DeductionUse: '% koji se koristi',
  DeductionFinalAmounut: 'Ukupni iznos odbitka €',
  DeductionDontUse: 'Iznos odbitka koji se NE koristi',
  DeductionDontUseAtAll: 'Ne koristi se',
  CityTaxPer: 'Postotak prireza',
  CityTaxId: 'Šifra grada/općine prebivališta',
  CityTaxName: 'Naziv grada/općine prebivališta',
  Neto: 'Neto Plaća €',
  NetoAdd: 'Neoporeziva naknada €',
  NetoAddDesc: 'Vrste i iznosi neoporezivih naknada',
  NetoAddType: 'Vrsta',
  NetoAddTypeName: 'Naziv vrste',
  NetoAddJoppd:'Joppd kolona',
  NetoAddAmounut: 'Iznos neoporezive naknade €',
  FinNeto3: 'Za isplatu €',
  SalToHead: 'Doprinosi na plaću',
  SalTo: 'Doprinos na',
  SalToDesc: 'Doprinos opis',
  SalToPer: '%',
  SalToAmount: 'Iznos €',
  TaxTax: 'Porez i Prirez',
  Tax20h: 'Porez ',
  Tax20: 'Porez osn ',
  Tax20Desc: '(do 3981.69 € mjesečno)',
  Tax20Am: 'Porez iznos',
  Tax30h: 'Porez ',
  Tax30: 'Porez osn',
  Tax30Desc: '(iznad 3981.69 € mjesečno)',
  Tax30Am: 'Porez 30% iznos',
  TaxOnTax: 'Prirez',
  TaxOnTaxAm: 'Prirez Iznos',
  TaxTotal: 'Porez + Prirez ukupno €=',
  Bruto3: 'UKUPAN TROŠAK RADA €',
  RedovanRad: 'Redovan Rad',
  NocniRad: 'Noćni Rad',
  PrekovremeniRad: 'Prekovremeni Rad',
  Nedjelja: 'Nedjelja',
  Blagdan:'Blagdan',
  Teren: 'Teren',
  PreraspodjelaRada: 'Preraspodjela Rada',
  Pripravnost: 'Pripravnost',
  DnevniOdmor: 'Dnevni Odmor',
  TjedniOdmor: 'Tjedni Odmor',
  NeradniBlagdan: 'Neradni Blagdan',
  GodisnjiOdmor: 'Godišnji Odmor',
  BolovanjeDo42Dana: 'Bolovanje Do 42 Dana',
  BolovanjeOd42Dana: 'Bolovanje Od 42 Dana',
  RodiljniDop: 'Rodiljni dop.',
  PlaćeniDopust: 'Plaćeni Dopust',
  NeplaćeniDopust: 'Neplaćeni Dopust',
  NenazočnostNaZahtjevRadnika: 'Nenazočnost Na Zahtjev Radnika',
  ZastojKrivnjomPoslodavca: 'Zastoj Krivnjom Poslodavca',
  Strajk: 'Štrajk',
  Lockout: 'Lockout',
  VrijemeMirovanjaRadnogOdnosa: 'Vrijeme Mirovanja Radnog Odnosa',
  UKUPNO: 'UKUPNO',
  PorezPrirezUkupno:'Porez + prirez ukupno:',
  PorezUkupno:'Porez ukupno:',
  NeoporeziviOdbitakFaktor: 'Neoporezivi odbitak (Faktor:',
  PoreznaOsnovica: 'Porezna osnovica ',
  IsplataRedovanRacun: 'Isplata redovan račun:',
  IsplataZasticeniRacun: 'Isplata zaštićeni račun:',

}

export default docHR